<template>
  <!-- Start About Area -->
  <div v-for="(Service, key) in Services" :key="key">
    <section class="section servicesection">
      <div class="container">
        <div class="row text-center">
          <div class="col-lg-12 col-md-12 col-12">
            <div>
              <h1>{{ Service.title }}</h1>
              <p>{{ Service.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="about-us section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-12 col-12">
            <div class="content-left">
              <img
                src="../../../assets/images/Data-pipeline.png"
                alt="#"
                class="ServiceImage"
              />
            </div>
          </div>
          <div class="col-lg-8 col-md-12 col-12">
            <!-- content-1 start -->
            <div class="content-right">
              <h2>{{ Service.analytics_title_1 }}</h2>
              <div class="row">
                <div class="col-lg-12 col-12">
                  <ul class="list">
                    <li>
                      <p
                        v-html="
                          $filters.markdown(Service.analytics_description_1)
                        "
                      ></p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-us section desktop">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-12 col-12">
            <!-- content-1 start -->
            <div class="content-right">
              <h2>{{ Service.analytics_title_2 }}</h2>
              <div class="row">
                <div class="col-lg-12 col-12">
                  <ul class="list">
                    <li>
                      <p
                        v-html="
                          $filters.markdown(Service.analytics_description_2)
                        "
                      ></p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-12">
            <div class="content-left">
              <img
                src="../../../assets/images/Data-pipeline-2.png"
                alt="#"
                class="ServiceImage"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-us section mobile">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-12 col-12">
            <div class="content-left">
              <img
                src="../../../assets/images/Data-pipeline-2.png"
                alt="#"
                class="ServiceImage"
              />
            </div>
          </div>
          <div class="col-lg-8 col-md-12 col-12">
            <!-- content-1 start -->
            <div class="content-right">
              <h2>{{ Service.analytics_title_2 }}</h2>
              <div class="row">
                <div class="col-lg-12 col-12">
                  <ul class="list">
                    <li>
                      <p
                        v-html="
                          $filters.markdown(Service.analytics_description_2)
                        "
                      ></p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-us section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-12 col-12">
            <div class="content-left">
              <img
                src="../../../assets/images/Data-pipeline-3.png"
                alt="#"
                class="ServiceImage"
              />
            </div>
          </div>
          <div class="col-lg-8 col-md-12 col-12">
            <!-- content-1 start -->
            <div class="content-right">
              <h2>{{ Service.analytics_title_3 }}</h2>
              <div class="row">
                <div class="col-lg-12 col-12">
                  <ul class="list">
                    <li>
                      <p
                        v-html="
                          $filters.markdown(Service.analytics_description_3)
                        "
                      ></p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End About Area -->
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      lang: "en",
      Services: [],
    };
  },
  beforeMount() {
    this.ActionUpdateStore();
  },
  methods: {
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/services/collections/enterprise_analytics/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
      };
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.Services = [];
            return;
          } else if (data.data.length == 0) {
            this.Services = [];
            return;
          }
          this.Services = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
/*======================================
    About Us CSS
========================================*/
img.ServiceImage {
  width: 100%;
}
.servicesection {
  padding: 150px 30px 30px 30px;
}

.content-left {
  text-align: center;
}

.content-left img {
  width: 100%;
  border-radius: 5px;
}
ul.list p {
  font-family: "Segoe UI", sans-serif;
}

@media (max-width: 998px) {
  .servicesection {
    padding: 30px;
  }
  .content-left img {
    width: 60%;
  }
  .content-right {
    margin-top: 20px;
    text-align: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .content-right {
    padding: 0px 30px;
    margin-top: 30px;
  }
  .desktop {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 2561px) {
  .mobile {
    display: none;
  }
}
.content-right h2 {
  display: block;
  font-size: 32px;
  margin-bottom: 10px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 700;
}
.about-us {
  padding: 30px 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .content-right h2 {
    font-size: 28px;
    line-height: 38px;
  }
}

@media (max-width: 767px) {
  .content-right h2 {
    font-size: 22px;
    line-height: 35px;
  }
  .about-us {
    padding: 30px 30px;
  }
  .Servicesection {
    padding: 50px 30px;
  }
}

.content-right h3 {
  font-size: 22px;
  margin-top: 40px;
  margin-bottom: 8px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
}

.content-right p {
  font-size: 15px;
  line-height: 35px;
  text-align: justify;
}

.content-right .list {
  margin-top: 10px;
  padding-left: 0px;
}

.content-right .list li {
  display: block;
  margin-bottom: 12px;
  font-size: 14px;
  color: #777;
  padding-left: 25px;
  position: relative;
}

.content-right .list li i {
  color: #0880e8;
  font-size: 15px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 3px;
}

.content-right .list li:last-child {
  margin: 0;
}
</style>
